import { StatusBar } from "expo-status-bar";
import React, { useState, useRef } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  FlatList,
  Text,
  Dimensions,
  Image,
  TextInput,
} from "react-native";
import * as firebase from "firebase";
import icons from "../constants/icons";
import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import { ScaledSheet } from "react-native-size-matters";
import { ArtistItem, SearchBlock } from "../components/global/ListItem";
import SectionBar from "../components/global/SectionBar";
import { SearchBar } from "react-native-elements";

export default function ({ navigation }) {
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get(
    "window"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState("");
  const tagRef = useRef();
  const searchSample = [
    {
      id: 1,
      query: "커피한잔 리필 할래요",
    },
    {
      id: 2,
      query: "옐로카드",
    },
    {
      id: 3,
      query: "김첨지",
    },
    {
      id: 4,
      query: "해피 덕",
    },
    {
      id: 5,
      query: "블루 블랙 커피",
    },
    {
      id: 6,
      query: "조수석에서 불러주던 노래",
    },
    {
      id: 7,
      query: "Why so different?",
    },
    {
      id: 8,
      query: "서른아홉즈음에",
    },
    {
      id: 9,
      query: "내 하루는 27시간",
    },
    {
      id: 10,
      query: "어쩌다 마주친 너희들",
    },
    {
      id: 11,
      query: "미안",
    },
    {
      id: 12,
      query: "untitled.",
    },
    {
      id: 13,
      query: "이름짓기는 어려워",
    },
    {
      id: 14,
      query: "REALLY!!",
    },
  ];

  const indieSongSample = [
    {
      id: 1,
      image: require("../assets/indie_1.png"),
      title: "맥주마시다 모였다",
      artist: "레드그린비어",
    },
    {
      id: 2,
      image: require("../assets/indie_2.png"),
      title: "의사들도 노래하고 싶어",
      artist: "메디컬체크",
    },
    {
      id: 3,
      image: require("../assets/indie_3.png"),
      title: "아저씨들의 느리지만 강한 힙합",
      artist: "라떼는말이지",
    },
    {
      id: 4,
      image: require("../assets/indie_4.png"),
      title: "디자이너들이 만든 노래와 음악",
      artist: "Designer-Songwriter",
      record: false,
    },
  ];

  const QuerySection = () => {
    return (
      <View style={styles.indieSongSection}>
        <View style={{ flex: 1, }}>
          <SectionBar
            title={"제 개인 취향입니다만?"}
            buttonName={"새로고침"}
            buttonOnPress={() => {
              console.log("제 개인 취향입니다만");
            }}
          />
        </View>
        <View style={{ marginTop:24,flex: 4 }}>
          <QueryList />
        </View>
      </View>
    );
  };

  {
    /* <TagGroup 
            style={{flexWrap:'wrap'}}
            tagStyle={{borderColor:Colors.gray}}
            ref={tagRef}
            source={searchSample.map(item=> item.query)}
            singleChoiceMode
            onSelectedTagChange={(selected) => { console.log(selected) }}
          /> */
  }

  const QueryList = () => {
    return (
      <View style={styles.queryListContainer}>
        {searchSample.map((item) => {
          return (
            <SearchBlock
              style={styles.searchBlock}
              key={item.id}
              text={item.query}
            />
          );
        })}
      </View>
    );
  };

  const handleSearch = (text) => {
    const formattedQuery = text.toLowerCase();
    const filteredData = searchSample.filter(
      (data) => data.query == formattedQuery
    );
    console.log(filteredData);
    setData(filteredData);
    setQuery(text);
  };

  const IndieSongSection = () => {
    return (
      <View style={styles.indieSongSection}>
        <View style={{ flex: 1,marginTop:24, }}>
          <SectionBar
            style={{minWidth:327}}
            title={"이번 주 인디밴드 소개"}
            buttonName={"전체보기"}
            buttonOnPress={() => {
              console.log("이번 주 인디밴드 소개 전체보기");
            }}
          />
        </View>
        <View style={{ flex: 4 }}>
          <IndieSongList />
        </View>
      </View>
    );
  };

  const IndieSongList = () => {
    return (
      <View style={styles.indieSongListContainer}>
        {indieSongSample.map((item) => {
          return (
            <ArtistItem
              styles={{ width: 375, height: 60 }}
              key={item.id}
              image={item.image}
              title={item.title}
              artist={"by " + item.artist}
            />
          );
        })}
      </View>
    );
  };

  const SearchBarContainer = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextInput
          autoCapitalize="none"
          numberOfLines={1}
          autoCorrect={false}
          clearButtonMode="always"
          value={query}
          onChangeText={(queryText) => handleSearch(queryText)}
          placeholder="원하는 곡 또는 아티스트를 검색해주세요"
          placeholderTextColor={Colors.white}
          style={styles.searchBar}
        />
        <Image
          style={{
            padding: 40,
            tintColor: Colors.white,
            maxWidth: 22,
            maxHeight: 22,
          }}
          source={icons.search}
        />
      </View>
    );
  };
  return (
    <Layout navigation={navigation} title="무슨 노래를 불러볼까요?" size={50}>
      <ScrollView style={styles.container}>
        <View style={{flex:1,flexDirection: 'column', alignItems: 'center'}}>
        <SearchBar
          placeholder="원하는 곡 또는 아티스트를 검색해주세요"
          onChangeText={(queryText) => handleSearch(queryText)}
          value={query}
          placeholderTextColor={Colors.gray}
          inputStyle={{ color: Colors.white, fontSize:14 }}
          containerStyle={{
            top:10,
            backgroundColor:'transparent',
            borderBottomColor: Colors.white,
            borderWidth:0,
            borderBottomWidth: 1,
            borderColor:'transparent',
            minWidth: 372,
            maxWidth:372,
            marginBottom:36,
          }}
          inputContainerStyle={{
            backgroundColor:'transparent',
            borderColor:'transparent',
            borderWidth:0,
            top:10,
          }}
        />
        </View>
        <QuerySection />
        <IndieSongSection />
      </ScrollView>
    </Layout>
  );
}

const styles = ScaledSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "column",
  },
  indieSongSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  indieSongListContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 23,
  },
  queryListContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    // justifyContent: "flex-start",
    paddingHorizontal: 20,
  },
  searchBlock: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    minWidth: 12,
    maxWidth: 323,
  },
  searchBar: {
    minWidth: 327,
    alignSelf: "center",
    backgroundColor: "transparent",
    borderBottomColor: Colors.white,
    borderBottomWidth: 1,
  },
});
