import React from "react";
import { View, StyleSheet, Image, ImageBackground } from "react-native";
import Colors from "../constants/colors";
import Button from "./button";
import icons from "../constants/icons";
import Text from "../components/utils/NotoFont";

const myCard = (props) => {
  return (
    <View style={styles.cardContainer}>
      <View style={{flex:1, flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
        <Text style={styles.title} bold>{props.title}</Text>
        <Image style={styles.label} source={props.label}/>
      </View>
      <View>
      <Text style={styles.text}>{props.author}</Text>
      </View>
      <View>
      <ImageBackground resizeMode="cover" style={styles.image} source={props.image}>
        <View style={{flex:1,flexDirection:"row", alignItems:"flex-end", justifyContent:"flex-start"}}>
          <Image style={styles.viewCountImg} source={icons.viewCount}/>
          <Text style={styles.viewCountTxt}>{props.views}</Text>
        </View>
      </ImageBackground>
      </View>
      <View style={{flex:1,flexDirection:"row", justifyContent:"flex-start"}}>        
        <Text style={styles.user} bold>{props.user}</Text>
        <Text numberofLines={1} style={styles.description}>{props.description}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    flexDirection: "column",
    width: 230,
    height: 289,
    marginHorizontal: 15,
    shadowColor: Colors.white,
    shadowOpacity: 0.1,
    shadowRadius: 20,
    shadowOffset: {
      height: 5,
      width: 1,
    },
    backgroundColor:Colors.black,
  },
  image:{
    paddingTop:23,
    width:200,
    height:200,
  },
  dayInfo: {
    marginTop: 20,
    flex: 1,
    flexDirection: "row",
  },
  memberBox: {
    marginTop: 20,
    width: 180,
    height: 40,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#999",
    alignItems: "center",
    alignContent: "center",
  },
  buttons: {
    marginLeft: -10,
    marginTop:30,
    height: 250,
    flex: 1,
    flexDirection: "row",
  },
  viewCountImg:{
    width:16,
    height:16,
    left:6,
    bottom:6
  },
  viewCountTxt:{
    width:25,
    height:12,
    fontSize:10,
    color:Colors.white,
    paddingLeft:6,
    paddingBottom:6
  },
  author:{
    fontSize:12,
    color:Colors.gray,
  },
  label:{
    width:32,
    height:17,
  },
  title: {
    color:Colors.white,
    left:15,
    fontSize: 14,
  },
  text: {
    left:15,
    fontSize: 12,
    color: Colors.gray,
  },
  user:{
    fontSize:12,
    color:Colors.white,
  },
  description:{
    paddingLeft:6,
    color:Colors.white,
    fontSize:10,
  }
});
export default React.memo(myCard);
