import React from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import Colors from "../constants/colors";

const button = (props) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
    >
      <View
        style={[styles.buttonContainer, { borderColor: props.borderColor, backgroundColor: props.backgroundColor  }]}
      >
        <Text style={[styles.text, {color: props.color}]}>
          {props.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 3,
    borderRadius: 10,
    height: 80,
    width: 240,
    marginHorizontal:10,
    
  },
  text: {
    fontSize: 30,
    fontWeight: "bold",
  },
});
export default React.memo(button);
