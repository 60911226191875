import { StatusBar } from 'expo-status-bar';
import React from 'react';
import {
	StyleSheet,
	TouchableOpacity,
	View,
	ActivityIndicator,
	Image
} from 'react-native';
import Layout from '../../components/global/Layout';
import Text from '../../components/utils/NotoFont';
import Colors from '../../constants/colors';

export default function ({ navigation }) {
	const B = (props) => <Text style={{fontWeight: 'bold'}}>{props.children}</Text>

	return (
		<Layout navigation={navigation}>
			<View
				style={styles.container}>
				<Image style={styles.logo} source={require("../../assets/logo.png")}/>
				<Image style={styles.logo} source={require("../../assets/logo2.png")}/>
				<Text style={styles.description}>노인을 이롭게, 시니어 맞춤 헬스케어 솔루션</Text>
				{/* This text using ubuntu font */}
			</View>
			<View style={styles.footerContainer}>
				<Text style={styles.footer}>@NORI CARE LTD RIGHT</Text>
			</View>
		</Layout>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		width:'100%',
		height:600,
	},
	title: {
		width:'95%',
		fontWeight: 'bold',
		fontSize:35,
		alignContent:'center',
		textAlign:'center'
	},
	description:{
		width:'80%',
		marginTop:100,
		fontSize:50,
		textAlign:'center',
		color:Colors.skyblue,
		fontWeight:'bold',
		alignContent:'center',
	},
	footerContainer:{
		height:100,
		top:-100,
	},
	footer:{
		width:'100%',
		fontSize:40,
		textAlign:'center',
		alignContent:'center',
	},
	logo: {
		width: '50%',
		height: 400,
	},
	logo2: {
		top:'5%',
		width: '50%',
		height: 200,
	},
  });
  