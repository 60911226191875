import { StatusBar } from "expo-status-bar";
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  FlatList,
  ImageBackground,
  Dimensions,
  Image,
  Text,
} from "react-native";
import * as firebase from "firebase";

import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import KaraokeWord from "../components/KaraokeWord";
// import BackgroundAnimation from "../components/BackgroundAnimation";
// import ParticleBackground from "react-native-particle-background";
import { ScaledSheet } from "react-native-size-matters";
import icons from "../constants/icons";
// import { AudioContext } from "../context/AudioProvider";
import { Audio } from "expo-av";
import { convertTime } from "../misc/helper";
import Slider from "@react-native-community/slider";
import { nFormatter } from "../components/utils/Numbers";
import env from "../constants/env";
import { SearchBar } from "react-native-elements";
// const lyricsData = require("../assets/sample/lyrics/Eminem - Rap God (Explicit).json");
// const songAudio = require("../assets/sample/audio/Eminem - Rap God (Explicit).mp3");
// const lyricsData = require("../assets/sample/lyrics/The Kid LAROI, Justin Bieber - STAY (Official Video).json");
// const songAudio = require("../assets/sample/audio/The Kid LAROI, Justin Bieber - STAY (Official Video).mp3");

export default function ({ route, navigation }) {
  const { id = null } = route.params ?? {};
  const songId = id;
  const url = `${env.apiUrl}/audio/${songId}`;
  const lyricsDataUrl = `${url}/alignment.json`;
  const songAudio = `${url}/audio.mp3`;
  const songVocal = `${url}/audio/vocals.mp3`;
  const songAccompaniment = `${url}/audio/accompaniment.mp3`;
  const [lyricsData, setLyricsData] = useState();
  const lyricsDataRef = useRef();
  const audio = {
    uri: songAudio,
  };

  const soundObjectRef = useRef(new Audio.Sound());
  const playbackStatusRef = useRef(null);
  const loopStartLine = useRef(null);
  const loopEndLine = useRef(null);
  const loopStartRef = useRef(null); // for setting part
  const loopEndRef = useRef(null);
  const isLooping = useRef(false); // For Looping itself
  const isPartRef = useRef(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  // const [playbackObject, setPlaybackObject] = useState(null);
  // const [playbackStatus, setPlaybackStatus] = useState(null);
  const [currentLine, setCurrentLine] = useState(0);
  const [totalLine, setTotalLine] = useState(0);
  const totalLineRef = useRef(0);
  const currentPositionRef = useRef(0);
  const currentLineRef = useRef(0);
  const [isLyricsFull, setIsLyricsFull] = useState(false);
  const [isLoop, setIsLoop] = useState(false);
  const [isPart, setIsPart] = useState(false);
  const currentSpeed = useRef(1);
  const [movingLine, setMovingLine] = useState(0);
  const [isMovingLine, setIsMovingLine] = useState(false);
  const movingLineRef = useRef(0);
  const [song, setSong] = useState();
  const [search, setSearch] = useState();
  const [isRecording, setIsRecording] = useState(false);
  // if moving line is set?
  // if is moving? follow moving line, else follow currentline;
  const [matches, setMatches] = useState([]);
  const matchesRef = useRef();
  const [currentMatch, setCurrentMatch] = useState();
  const calculateSeebBar = () => {
    if (
      playbackStatusRef.current.positionMillis !== null &&
      playbackStatusRef.current.durationMillis !== null
    ) {
      return (
        playbackStatusRef.current.positionMillis /
        playbackStatusRef.current.durationMillis
      );
    }

    if (currentAudio.lastPosition) {
      return currentAudio.lastPosition / (currentAudio.duration * 1000);
    }

    return 0;
  };

  useEffect(() => {
    console.log("Rerender parents!");
    const onPlaybackStatusUpdate = (playbackStatus) => {
      if (!playbackStatus.isLoaded) {
        // Update your UI for the unloaded state
        if (playbackStatus.error) {
          console.log(
            `Encountered a fatal error during playback: ${playbackStatus.error}`
          );
          // Send Expo team the error on Slack or the forums so we can help you debug!
        }
      } else {
        // Update your UI for the loaded state

        if (playbackStatus.isPlaying) {
          // Update your UI for the playing state
          currentPositionRef.current = parseInt(
            playbackStatus.positionMillis,
            10
          );
          setCurrentPosition(currentPositionRef.current);
          handleLine();
        } else {
          // Update your UI for the paused state
        }

        if (playbackStatus.isBuffering) {
          // Update your UI for the buffering state
        }

        if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
          // The player has just finished playing and will stop. Maybe you want to play something else?
          setIsPlaying(false);
        }
        if (isPartRef.current) {
          console.log(playbackStatus.positionMillis, loopEndRef.current);
          if (playbackStatus.isPlaying) {
            if (playbackStatus.positionMillis >= loopEndRef.current) {
              console.log("Stop and start again!");
              currentPositionRef.current = loopStartRef.current;
              setCurrentPosition(currentPositionRef.current);
              handleMove(currentPositionRef.current);
              moveLine(loopStartLine.current);
            }
          }
        }
      }
    };
    soundObjectRef.current.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);

    // if (soundObjectRef.current === null) {
    //   console.log("No playbackObject exist!");
    //   // setPlaybackObject(new Audio.Sound());

    // }
    return soundObjectRef.current
      ? () => {
          console.log("unload sound");
          soundObjectRef.current.unloadAsync();
        }
      : undefined;
  }, []);

  const changeToMR = async () =>{
    soundObjectRef.current.unloadAsync();
    const status = soundObjectRef.current.loadAsync(
      { uri: songAccompaniment },
      { shouldPlay: true, progressUpdateIntervalMillis: 500 }
    );
    playbackStatusRef.current = status;
    setCurrentPosition(0);
    setCurrentLine(0);
    currentPositionRef.current = 0;
    currentLineRef.current = 0;  
  }

  const changeToVocal = () =>{
    soundObjectRef.current.unloadAsync();
    const status = soundObjectRef.current.loadAsync(
      { uri: songVocal },
      { shouldPlay: true, progressUpdateIntervalMillis: 500 }
    );
    playbackStatusRef.current = status;
    setCurrentPosition(0);
    setCurrentLine(0);
    currentPositionRef.current = 0;
    currentLineRef.current = 0;  
  }

  const changeToOriginal = () =>{
    soundObjectRef.current.unloadAsync();
    const status = soundObjectRef.current.loadAsync(
      { uri: songAudio },
      { shouldPlay: true, progressUpdateIntervalMillis: 500 }
    );
    playbackStatusRef.current = status;
    setCurrentPosition(0);
    setCurrentLine(0);
    currentPositionRef.current = 0;
    currentLineRef.current = 0;  
  }

  useEffect(() => {
    const status = soundObjectRef.current.loadAsync(
      { uri: audio.uri },
      { shouldPlay: false, progressUpdateIntervalMillis: 500 }
    );
    console.log("Set is playing");
    setIsPlaying(false);
    playbackStatusRef.current = status;

    // setPlaybackStatus(status);
  }, [audio.uri]);
  // const registerAudio = async () => {
  //   console.log("Register Audio");
  //   const status = await soundObjectRef.current.loadAsync(
  //     { uri: audio.uri },
  //     { shouldPlay: true, progressUpdateIntervalMillis: 1000 }
  //   );
  //   // soundObjectRef.current.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);

  //   setIsPlaying(true);
  //   return setPlaybackStatus(status);
  // };

  // const onPlaybackStatusUpdate = async (playbackStatus) => {
  //   if (playbackStatus.isLoaded && playbackStatus.isPlaying) {
  //     currentPositionRef.current = parseInt(playbackStatus.positionMillis,10);
  //     setCurrentPosition(currentPositionRef.current);
  //   }
  //   if (playbackStatus.didJustFinish) {
  //     setIsPlaying(false);
  //   }
  //   handleLine();
  // };

  async function getSongDetail() {
    await fetch(`${env.apiUrl}/api/songs/${id}`, {
      // headers:{
      //   Authorization: await getToken(),
      // }
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setSong(json);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    (async () => {
      console.log("GET DETAIL!");
      await getSongDetail();
    })();
  }, []);

  useEffect(() => {
    let url = lyricsDataUrl;
    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setLyricsData(json),
          setTotalLine(json.length),
          (totalLineRef.current = json.length),
          (lyricsDataRef.current = json);
      });
  }, [totalLine]);

  const handleAudioPlayPause = async () => {
    console.log("HandleAUdio!");
    if (soundObjectRef.current !== null && playbackStatusRef.current === null) {
      // await registerAudio();
    } else {
      // It will pause our audio
      if (playbackStatusRef.current.isPlaying) {
        const status = await soundObjectRef.current.pauseAsync();
        console.log("Set is playing");
        setIsPlaying(false);
        playbackStatusRef.current = status;
        playbackStatusRef.current.isPlaying = false;
        return playbackStatusRef.current;

        // return setPlaybackStatus(status);
      }

      // It will resume our audio
      if (!playbackStatusRef.current.isPlaying) {
        const status = await soundObjectRef.current.playAsync();
        console.log("Set is playing");
        setIsPlaying(true);
        playbackStatusRef.current.isPlaying = true;
        return playbackStatusRef.current;

        // return setPlaybackStatus(status);
      }
    }
  };

  const handleSpeed = async (amount) => {
    currentSpeed.current = amount;
    console.log("currentSpeed.current:" + currentSpeed.current);
    const status = await soundObjectRef.current.setRateAsync(
      currentSpeed.current,
      true,
      "High"
    );
    console.log("Set is playing");
    setIsPlaying(true);
    return (playbackStatusRef.current = status);
  };

  const handleLoopBetween = async (part) => {
    console.log("Start:", part.start, "end:", part.end);
    const status = await soundObjectRef.current.playFromPositionAsync(
      part.start
    );
    loopStartRef.current = part.start;
    loopEndRef.current = part.end;

    console.log("Set is playing");
    setIsPlaying(true);
    return (playbackStatusRef.current = status);
  };

  const handleMove = async (position) => {
    if (soundObjectRef.current !== null && playbackStatusRef.current === null) {
      // await registerAudio();
    } else {
      // Find closest position of start of LyricsData;
      for (let i = 0; i < lyricsDataRef.current.length; i++) {
        if (lyricsDataRef.current[i][0].start * 1000 < loopStartRef.current) {
          if (
            lyricsDataRef.current[i][lyricsDataRef.current[i].length - 1].end *
              1000 >
            loopStartRef.current
          ) {
            currentLineRef.current = i;
            break;
          }
        }
      }
      console.log("currentLineRef.current:" + currentLineRef.current);
      // setCurrentLine(currentLineRef.current);
      const status = await soundObjectRef.current.playFromPositionAsync(
        position
      );
      return (playbackStatusRef.current = status);

      // return setPlaybackStatus(status);
    }
  };

  const options = {
    apiKey: "FOy263eo4ANrxeeM4NhyyLtVSk5br0Hm7G11xuz2QWt2mqfWcKWzIphDMwrE5M2s",
    title: "rollin",
    artist: "bravegirls",
    optimizeQuery: true,
  };

  async function handleSignOut() {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.log(error);
    }
  }

  const _onFinish = () => {
    // Alert.alert('Animation', 'It is done!');
  };

  const getRandomColor = (opacity = 0.2) => {
    return (
      "rgba(" +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      "," +
      opacity +
      ")"
    );
  };

  const nextLine = () => {
    console.log("NextLine Called!");
    if (!isMovingLine) {
      currentLine == totalLineRef.current - 1
        ? (currentLineRef.current = 0)
        : (currentLineRef.current = currentLineRef.current + 1);
      setCurrentLine(currentLineRef.current);
    } else {
      // pressed for the first time, should update line ...
      movingLine == totalLineRef.current - 1
        ? (movingLineRef.current = 0)
        : (movingLineRef.current = movingLineRef.current + 1);
      setMovingLine(movingLineRef.current);
    }
  };

  const prevLine = () => {
    if (!isMovingLine) {
      console.log("prevline:" + currentLine);
      currentLine <= 0
        ? (currentLineRef.current = totalLineRef.current - 1)
        : (currentLineRef.current = currentLineRef.current - 1);
      setCurrentLine(currentLineRef.current);
    } else {
      movingLine <= 0
        ? (movingLineRef.current = totalLineRef.current - 1)
        : (movingLineRef.current = movingLineRef.current - 1);
      setMovingLine(movingLineRef.current);
    }
  };

  const getLoopingCurrent = (line) => {
    let currentSentence = lyricsDataRef.current.slice(line, line + 1);
    let firstWord = currentSentence[0][0];
    let lastWord = currentSentence[0][currentSentence[0].length - 1];
    currentLineRef.current = line;
    loopStartLine.current = currentLineRef.current;
    let start = firstWord.start * 1000;
    let end = lastWord.end * 1000;
    return { start, end };
  };

  const updateSearch = (text) => {
    setSearch(text);
    if (text.length > 1) {
      const re = RegExp(
        `.*${text
          .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
          .toLowerCase()
          .split("")
          .join(".*")}.*`
      );

      // [ 'Belgium', 'Brest' ]
      let arrays = [];
      lyricsDataRef.current.map((item, i) => {
        item.map((word, j) => {
          word.word
            .toLowerCase()
            .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
            .match(re) && arrays.push({ i, j });
        });
      });
      console.log(arrays);
      if (arrays.length > 0) {
        matchesRef.current = arrays;
        console.log(matchesRef.current);
        setCurrentMatch(0);
        setMatches(arrays);
      }
    } else {
      setCurrentMatch(null);
      setMatches([]);
    }
  };

  const moveByWord = (index) => {
    console.log(matchesRef.current);
    index <= 0 ? (index = matchesRef.current.length) : index;
    index > matchesRef.current.length ? (index = 1) : index;
    setCurrentMatch(index);
    setIsMovingLine(true);
    console.log(index);
    console.log(matchesRef.current[index - 1]);
    console.log(matchesRef.current[index - 1]["j"]);
    movingLineRef.current = matchesRef.current[index - 1]["i"];
    setMovingLine(movingLineRef.current);
  };

  const PlayerContainer = (props) => {
    return (
      <View style={styles.playerContainer}>
        <ControlButton
          source={isPart ? icons.whole : icons.wholeA}
          size={46}
          onPress={() => {
            isPartRef.current = false;
            setIsPart(false);
          }}
          isText
          name={"전곡듣기"}
        />
        <RecordButton onPress={props.onPress} />
        <ControlButton
          source={isPart ? icons.repeatA : icons.repeat}
          size={46}
          onPress={() => {
            isPartRef.current = true;
            setIsPart(true);
            handleLoopBetween(getLoopingCurrent(currentLineRef.current));
          }}
          isText
          name={"부분듣기"}
        />
      </View>
    );
  };
  const ControlButton = React.memo((props) => {
    return (
      <TouchableOpacity
        activeOpacity={0.7}
        style={{
          width: props.size ? props.size : 24,
          height: props.size ? props.size : 24,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: props.marginLeft,
        }}
        onPress={props.onPress}
      >
        <Image
          source={props.source}
          style={[
            {
              width: props.size ? props.size : 24,
              height: props.size ? props.size : 24,
            },
            props.flip && { transform: [{ scaleX: -1 }] },
          ]}
        />
        {props.isText && (
          <Text style={{ color: Colors.white, fontSize: 12, paddingTop: 10 }}>
            {props.name}
          </Text>
        )}
      </TouchableOpacity>
    );
  });

  const RecordButton = React.memo((props) => {
    return (
      <ImageBackground
        resizeMode="cover"
        style={styles.recordBtnBg}
        source={icons.recordBg}
      >
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.recordBtn}
          onPress={props.onPress}
        >
          <Image
            source={isPlaying ? icons.pauseBtn : icons.playBtn}
            style={[styles.recordBtnImg]}
          />
        </TouchableOpacity>
      </ImageBackground>
    );
  });

  // Speed Controller
  const [expanded, setExpanded] = useState({
    0: false,
    1: true,
    2: false,
    3: false,
  });

  const SpeedItem = (props) => {
    return (
      <TouchableOpacity
        style={{ flex: 1 }}
        onPress={() => {
          handleSpeed(props.value);
          setExpanded(
            Object.keys(expanded).map((item) =>
              item == props.id
                ? (expanded[item] = true)
                : (expanded[item] = false)
            )
          );
        }}
      >
        <Text
          style={[
            styles.speedController,
            { color: expanded[props.id] ? Colors.white : Colors.lightGray },
          ]}
        >
          {props.value.toFixed(2)}
        </Text>
      </TouchableOpacity>
    );
  };
  const SpeedController = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          height: 12,
          width: 335,
          marginBottom:20,
        }}
      >
        <Text style={[styles.speedController, { marginRight: 10 }]}>
          {"재생속도"}
        </Text>
        <SpeedItem value={0.5} id={0} />
        <SpeedItem value={1.0} id={1} />
        <SpeedItem value={1.5} id={2} />
        <SpeedItem value={2.0} id={3} />
        {isMovingLine ? <ReturnToCurrent
            onPress={() => {
              setIsMovingLine(false);
              movingLineRef.current = movingLine;
            }}
        />:<View style={{flex:4}}></View>}
      </View>
    );
  };

  const ReturnToCurrent = (props) => {
    return (
      <TouchableOpacity onPress={props.onPress}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <View
            style={{
              width: 8,
              height: 8,
              borderRadius: 4,
              backgroundColor: Colors.primary,
              top: 4,
            }}
          ></View>
          <Text style={[styles.speedController, [{ marginLeft: 10 }]]}>
            {"현재 재생중인 소절로 이동"}
          </Text>
        </View>
      </TouchableOpacity>
    )
  };

  const ExtraContainer = () => {
    return (
      <View style={styles.extraContainer}>
        <View
          style={{
            flex: 2,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            <ControlButton source={icons.heart} onPress={() => {}} />
            <Text style={{ color: Colors.white }}>{nFormatter(124241241)}</Text>
          </View>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <ControlButton source={icons.comment} onPress={() => {}} />
            <Text style={{ color: Colors.white }}>{nFormatter(51299)}</Text>
          </View>
        </View>
        <View
          style={{ flex: 2, flexDirection: "row", justifyContent: "flex-end" }}
        >
          <ControlButton
            source={icons.user}
            onPress={() => {
              prevLine();
            }}
            marginLeft={10}
          />
          <ControlButton
            source={icons.save}
            onPress={() => {
              prevLine();
            }}
            marginLeft={10}
          />

          <ControlButton
            source={icons.share}
            onPress={() => {
              prevLine();
            }}
            marginLeft={10}
          />
        </View>
      </View>
    );
  };
  const ExtraButton = (props) => {
    return (
      <TouchableOpacity activeOpacity={0.7} onPress={props.onPress}>
        <Image source={props.source} style={[styles.extraBtn]} />
        {/* <Text style={styles.extraBtnTxt}>{props.text}</Text> */}
      </TouchableOpacity>
    );
  };

  const SegmentButton = () => {
    return (
      <View style={styles.segmentContainer}>
        <TouchableOpacity
          activeOpacity={0.7}
          style={[
            styles.segmentBtn,
            {
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            isPart ? styles.inActiveBtn : styles.activeBtn,
          ]}
          onPress={() => {
            setPart(false);
          }}
        >
          <Text
            style={[
              styles.segmentBtnTxt,
              isPart ? styles.inActiveBtnTxt : styles.activeBtnTxt,
            ]}
          >
            전곡 부르기
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.7}
          style={[
            styles.segmentBtn,
            {
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            },
            isPart ? styles.activeBtn : styles.inActiveBtn,
          ]}
          onPress={() => {
            setPart(true);
          }}
        >
          <Text
            style={[
              styles.segmentBtnTxt,
              isPart ? styles.activeBtnTxt : styles.inActiveBtnTxt,
            ]}
          >
            부분 선택 부르기
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  // Should show current Position's Sentences only. Highlight current Sentence and disable others.

  const KaraokeSentence = React.memo((props) => {
    return (
      <View
        style={[
          props.style,
          {
            flexDirection: "row",
            alignSelf: "center",
            maxWidth: 327,
            maxHeight: 500,
            flexWrap: "wrap",
            justifyContent: "center",
          },
        ]}
      >
        {props.sentence.map((item, index) => {
          return (
            <View key={index}>
              <KaraokeWord
                style={{
                  alignItems: "baseline",
                  marginHorizontal: 5,
                  marginVertical: 5,
                }}
                text={item.word}
                start={item.start * 1000}
                currentPosition={currentPositionRef.current}
                duration={(item.end - item.start) * 1000}
                onPress={() => {
                  setIsPlaying(true);
                  handleMove(item.start * 1000).then(res => {
                    currentLineRef.current = movingLineRef.current;
                    setCurrentLine(currentLineRef.current);
                    setIsMovingLine(false);
                  });


                }}
                isFound={ ((matches.length) > 0 && currentMatch > 0 )  && index === matchesRef.current[currentMatch - 1]["j"] }
              />
            </View>
          );
        })}
      </View>
    );
  });

  // Move line to set actual currentLine position
  const moveLine = async (line) => {
    line = parseInt(line);
    let currentSentence = lyricsDataRef.current.slice(line, line + 1);
    let firstWord = currentSentence[0][0];
    currentLineRef.current = line;
    // handleMove(firstWord.start * 1000);
  };

  //Called to auto increase line
  const handleLine = () => {
    if (currentLineRef.current >= totalLineRef.current - 1) {
      currentLineRef.current = totalLineRef.current - 1;
      return;
    }
    let currentSentence = lyricsDataRef.current.slice(
      currentLineRef.current,
      currentLineRef.current + 1
    );

    let lastWord = currentSentence[0][currentSentence[0].length - 1];
    if (
      currentPositionRef.current >= lastWord.end * 1000 &&
      currentLineRef.current <= totalLineRef.current - 1
    ) {
      nextLine();
    }
  };
  //Check current position. And update if karaoke sentence's last word's end time is not passed yet.

  //When to play animnation? When time passed play animation on reach start.

  const LyricsController = React.memo((props) => {
    return (
      <View
        style={{
          height: 40,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ControlButton
          source={icons.next}
          flip
          size={40}
          onPress={() => {
            isMovingLine
              ? (movingLineRef.current = movingLineRef.current)
              : (movingLineRef.current = currentLineRef.current);
            setIsMovingLine(true);
            prevLine();
          }}
        />
        <Text
          style={{ fontSize: 20, color: Colors.white, paddingHorizontal: 10 }}
          bold
        >
          {(isMovingLine ? movingLine + 1 : currentLine + 1) + "/" + totalLine}
        </Text>
        <ControlButton
          source={icons.next}
          size={40}
          onPress={() => {
            isMovingLine
              ? (movingLineRef.current = movingLineRef.current)
              : (movingLineRef.current = currentLineRef.current);
            setIsMovingLine(true);
            nextLine();
          }}
        />
      </View>
    );
  });

  const LyricsContainer = React.memo(() => {
    return (
      <View style={{ alignSelf: "center" }}>
        {lyricsDataRef.current
          .slice(
            isMovingLine ? movingLineRef.current : currentLineRef.current,
            isMovingLine
              ? movingLineRef.current + 1
              : currentLineRef.current + 1
          )
          .map((item, index) => {
            return <KaraokeSentence sentence={item} key={index} />;
          })}
      </View>
    );
  });
  const FullLyricsContainer = React.memo(() => {
    return (
      <View style={{ alignSelf: "center", flexDirection: "column" }}>
        {lyricsDataRef.current.map((item, index) => {
          return (
            <KaraokeSentence
              style={{ paddingVertical: 50 }}
              key={index}
              sentence={item}
              key={index}
            />
          );
        })}
      </View>
    );
  });

  const LyricsSlider = (props) => {
    return (
      <Slider
        style={{ alignSelf: "center", width: 342, height: 20 , marginBottom:15}}
        minimumValue={0}
        maximumValue={props.totalLine}
        value={props.currentLine + 1}
        minimumTrackTintColor={Colors.primary}
        maximumTrackTintColor={Colors.gray}
        thumbTintColor={Colors.primary}
        onValueChange={(value) => {
          // setCurrentPosition(
          //   convertTime(value * playbackObject.durationMillis)
          // );
        }}
        onSlidingStart={async () => {
          if (!isPlaying) return;

          try {
            // await pause(playbackObject);
          } catch (error) {
            console.log("error inside onSlidingStart callback", error);
          }
        }}
        onSlidingComplete={async (value) => {
          setIsMovingLine(true);
          await moveLine(value);
        }}
      />
    );
  };
  const HeaderButtons = () => {
    return (
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flexDirection: "column" }}>
          <SearchBar
            placeholder="가사를 검색해보세요"
            onClear={() => {
              currentLineRef.current = movingLineRef.current;
              setCurrentLine(currentLineRef.current);
              setIsMovingLine(false);
            }}
            onChangeText={(queryText) => updateSearch(queryText)}
            value={search}
            placeholderTextColor={Colors.gray}
            inputStyle={{ color: Colors.white, fontSize: 14 }}
            containerStyle={{
              backgroundColor: "transparent",
              borderBottomColor: Colors.white,
              borderWidth: 0,
              borderColor: "transparent",
              height: 50,
              width: 335,
            }}
            inputContainerStyle={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              borderWidth: 0,
            }}
          />
          {matches.length > 0 && (
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  moveByWord(currentMatch - 1);
                }}
              >
                <Image
                  source={icons.searchNext}
                  style={{ width: 24, height: 24, transform: [{ scaleX: -1 }] }}
                />
              </TouchableOpacity>
              <Text style={{ color: Colors.white, fontSize: 14 }}>
                {currentMatch + "/" + matches.length}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  moveByWord(currentMatch + 1);
                }}
              >
                <Image
                  source={icons.searchNext}
                  style={{ width: 24, height: 24 }}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
        <TouchableOpacity
          style={{ width: 30, height: 30 }}
          onPress={() => {
            setIsLyricsFull(!isLyricsFull);
          }}
        >
          <Image
            source={isLyricsFull ? icons.lyricsA : icons.lyrics}
            style={{ width: 30, height: 30 }}
          />
        </TouchableOpacity>
      </View>
    );
  };
  const Header = () => {
    return (
      <View style={styles.descriptionContainer}>
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={() => {
            navigation.navigate('Upload');
          }}
        >
          <Image
            source={icons.back}
            style={{ width: 30, height: 30, tintColor: Colors.white }}
          />
        </TouchableOpacity>
        {song && (
          <View style={{ flex: 5, flexDirection: "column" }}>
            <Text
              bold
              style={{ fontSize: 16, color: Colors.white, alignSelf: "center" }}
            >
              {song.title}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: Colors.lightGray,
                alignSelf: "center",
              }}
            >
              {"by " + song.artist}
            </Text>
          </View>
        )}
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={() => {
            setIsRecording(!isRecording);
            isRecording ? changeToOriginal() : changeToMR();
          }}
        >
          <Image
            source={isRecording ? icons.changeRecordBtn : icons.changeListenBtn}
            style={{ width: 30, height: 30 }}
          />
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <Header />
        {HeaderButtons()}
        {isLyricsFull ? (
          <ScrollView style={{ height: 450 }}>
            {lyricsDataRef.current && <FullLyricsContainer />}
          </ScrollView>
        ) : (
          <View
            style={{
              height: 450,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            {lyricsDataRef.current && <LyricsContainer />}
          </View>
        )}
        {!isLyricsFull && (
          <View>
            <View
              style={{
                height: 52,
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <SpeedController />

              </View>
              {/* {((totalLine > 0) )&&
                <LyricsSlider
                  totalLine={totalLineRef.current}
                  currentLine={currentLine}
                />
               } */}
              <LyricsController />
            </View>
            <PlayerContainer onPress={handleAudioPlayPause} />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = ScaledSheet.create({
  descriptionContainer: {
    top: 10,
    height: 37,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  description: {
    justifyContent: "center",
    textAlign: "center",
    fontSize: "12@ms",
    color: Colors.white,
  },
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: Colors.black,
  },
  lyricsContainer: {
    height: 327,
    width: "100%",
  },
  segmentContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: 327,
    height: 52,
  },
  playerContainer: {
    marginTop: 20,
    height: 80,
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },

  extraContainer: {
    margin: 20,
    width: 327,
    height: 24,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardContainer: {
    top: "100@vs",
    width: "98%",
    left: "1%",
  },
  cardList: {
    margin: "15@s",
    width: "100%",
  },
  userInfo: {
    top: "50@vs",
    left: "50@s",
    flexDirection: "column",
  },
  userInfoTitle: {
    fontSize: "30@ms",
    fontWeight: "bold",
  },
  userInfoClass: {
    marginTop: "30@vs",
    fontWeight: "bold",
    fontSize: "30@ms",
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    color: Colors.footerText,
    fontSize: "20@ms",
    fontWeight: "bold",
  },
  textStyle: {
    top: 380,
    fontSize: 36,
    width: 327,
    height: 180,
  },
  image: {
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  overlay: {
    zIndex: -1,
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  tabIcon: {
    top: "16@vs",
    width: "28@s",
    height: "16@vs",
  },
  recordBtnBg: {
    width: 140,
    height: 140,
  },
  recordBtn: {
    width: 80,
    height: 80,
    borderRadius: 40,
    top: 24,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  recordBtnImg: {
    width: 80,
    height: 80,
  },
  segmentBtn: {
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
    width: 165,
    height: 52,
    borderColor: Colors.skyblue,
    borderWidth: 2,
  },
  activeBtn: {
    backgroundColor: Colors.skyblue,
  },
  inActiveBtn: {
    backgroundColor: "transparent",
  },
  segmentBtnTxt: {
    fontSize: 16,
    fontFamily: "NotoB",
    color: Colors.white,
  },
  activeBtnTxt: {
    color: Colors.white,
  },
  inActiveBtnTxt: {
    color: Colors.gray,
    fontFamily: "NotoR",
  },
  extraBtn: {
    width: 28,
    height: 28,
  },
  extraBtnTxt: {
    fontSize: 10,
    textAlign: "center",
    color: Colors.white,
  },
  speedController: {
    color: Colors.white,
    fontSize: 12,
  },
});
