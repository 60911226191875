import React, { useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import {
	ScrollView,
	TouchableOpacity,
	View,
	KeyboardAvoidingView,
	ActivityIndicator,
	TextInput,
	Image,
	StyleSheet,
	Text,
} from 'react-native';
import * as firebase from 'firebase';

import Layout from '../components/global/Layout';
import Colors from '../constants/colors';

export default function ({ navigation }) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	async function login() {
		setLoading(true);
		await firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.catch(function (error) {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				// ...
				setLoading(false);
				alert(errorMessage);
			});
    }
    
    firebase.auth().onAuthStateChanged(user => {
        if (user != null) {
            console.log();
          console.log('We are authenticated now!');
        }
      
        // Do other things
      });
      
      
	return (
		<KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
			<Layout navigation={navigation}>
					<View style={styles.container}>
                        <Image
                            style={styles.logo}
							resizeMode="contain"
							source={require('../assets/logo.png')}
						/>

						<View style={styles.textInputContainer}>
							<TextInput
								style={styles.textInput}
								placeholder="ID"
								placeholderStyle={{
									fontFamily: 'NotoR',
								}}
								value={email}
								autoCapitalize="none"
								autoCompleteType="off"
								autoCorrect={false}
								keyboardType="email-address"
								onChangeText={(text) => setEmail(text)}
							/>
						</View>
						<View style={styles.textInputContainer}>
							<TextInput
								style={styles.textInput}
								placeholder="PW"
								placeholderStyle={{
									fontFamily: 'NotoR',
								}}
								value={password}
								autoCapitalize="none"
								autoCompleteType="off"
								autoCorrect={false}
								secureTextEntry={true}
								onChangeText={(text) => setPassword(text)}
							/>
						</View>
						<View style={styles.textInputContainer}>
                        <TouchableOpacity
							onPress={() => {
								login();
							}}
							disabled={loading}
							style={{
                                top:50,
                                width:"100%"
							}}
						>
							<View style={styles.button}>
								{loading ? (
									<ActivityIndicator size="large" color="#fff" />
								) : (
									<Text style={styles.buttonText}>
										로그인
									</Text>
								)}
							</View>
						</TouchableOpacity>
                        </View>
					</View>
                    <View style={styles.footerContainer}>
		        		<Text style={styles.footer}>UNDERWAVE</Text>
        			</View>

			</Layout>
		</KeyboardAvoidingView>
	);
}
const styles = StyleSheet.create({
	container: {
        top:"-10%",
        flex: 3,
        paddingHorizontal: 20,
        paddingBottom: 20,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
},
    logo:{
        width: "30%",
        height: 300,
    },
	textInputContainer: {
        width:"50%",
		marginTop: 15,
		backgroundColor: '#FFF',
		borderColor: '#d8d8d8',
		borderWidth: 1,
		borderRadius: 8,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent:'center',
	},
	textInput: {
		padding: 10,
        height:100,
		paddingHorizontal: 20,
		textAlign: 'left',
        color: 'black',
        fontSize:50,
		flex: 1,
		fontFamily: 'NotoR',
	},
	button: {
        width:"100%",
        height:100,
		padding: 15,
		backgroundColor: Colors.gray,
		borderRadius: 8,
    },
    buttonText:{
        fontSize: 50, 
        color: Colors.black,
        textAlign: 'center',
    },
	footerContainer:{
		height:100,
		top:-100,
	},
	footer:{
		width:'100%',
		fontSize:40,
		textAlign:'center',
		alignContent:'center',
	},

});
