const background = '#000000';
const primary = '#0067C5';
const topNavText = '#000';
const black = '#000000';
const footer = '#AAAAAA';
const footerText = '#555555';
const skyblue = '#0067C5';
const gray = '#979797';
const ActiveTab = '#0099FF';
const inActiveTab = '#979797';
const highlight = '#CC0000';
const white = '#FFFFFF';
const lightBlue = '#5CBEFF';
const lightGray = '#C4C4C4';
const grayBtn = '#767676';
const warn = "#EF5C5C";
export default {
	background,
	primary,
	topNavText,
	black,
	footer,
	footerText,
	skyblue,
	gray,
	ActiveTab,
	inActiveTab,
	highlight,
	white,
	lightBlue,
	lightGray,
	grayBtn,
	warn,
};
