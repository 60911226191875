import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  FlatList,
  Dimensions,
  Image,
  ImageBackground,
} from "react-native";
import * as firebase from "firebase";
import { ScaledSheet } from 'react-native-size-matters';

import Layout from "../components/global/Layout";
import Text from '../components/utils/NotoFont';
import Colors from "../constants/colors";
import Footer from "../components/global/Footer";
import MyCard from "../components/myCard";
import SectionBar from "../components/global/SectionBar"
import Avatar from "../components/global/Avatar";
import icons from "../constants/icons";
import ListItem, {
  BigButton,
} from "../components/global/ListItem";

export default function ({ navigation }) {
const {
	width: SCREEN_WIDTH,
	height: SCREEN_HEIGHT,
	} = Dimensions.get('window');
	  
  const userInfo = {
    name: "데이비드 싱",
    profile: require("../assets/profile.png"),
    background: require("../assets/profile_background.png"),
    description:"dkdkkdkkdkdkdkkd!!",
  };

  
  const mySongSample = [
    {
      id: 1,
      image: require("../assets/artist1.png"),
      title: "커피 한 잔 리필할래요",
      artist: "자취생",
      record: false,
    },
    {
      id: 2,
      image: require("../assets/artist2.png"),
      title: "냐옹이가 냥냥냥",
      artist: "집사밴드",
      record: false,
    },
    {
      id: 3,
      image: require("../assets/artist3.png"),
      title: "그대와 함께한 천도 복숭아",
      artist: "산악회",
      record: false,
    },
    {
      id: 4,
      image: require("../assets/artist4.png"),
      title: "눈물로 그린 그림",
      artist: "샅바두른 달리",
      record: false,
    },
  ];


  const reservedSongSample = [
    {
      id: 1,
      image: require("../assets/artist5.png"),
      title: "니가 남긴 영수증",
      artist: "블루블루",
      record: true,
    },
    {
      id: 2,
      image: require("../assets/artist6.png"),
      title: "오래전 그날, 우리",
      artist: "검정보라치마",
      record: true,
    },
    {
      id: 3,
      image: require("../assets/artist7.png"),
      title: "음치기 박치기",
      artist: "음치박치",
      record: true,
    },
    {
      id: 4,
      image: require("../assets/artist8.png"),
      title: "행복하지 말지 말아요",
      artist: "엠씨더미니멈",
      record: false,
    },
  ];

  
  const MySongSection = () => {
    return (
      <View style={styles.reservedSongSection}>
		<View style={{flex:1}}>
        <SectionBar
          title={"내가 부른"}
          buttonName={"전체보기"}
          buttonOnPress={() => {
            console.log("최근에 부른 전체보기");
          }}
        />
		</View>
		<View style={{flex:4}}>
			<MySongList />
		</View>
      </View>
    );
  };

  const MySongList = () => {
    return (
      <View style={styles.reservedSongListContainer}>
        {mySongSample.map((item) => {
          return (
            <ListItem
              styles={{ width: 375, height: 69 }}
              key={item.id}
              image={item.image}
              title={item.title}
              artist={"by " + item.artist}
              imageA={item.record ? icons.recordS : icons.play}
              imageB={icons.more}
              buttonAPress={() => {
                item.record ? console.log("A-Record") : console.log("A-Play");
              }}
              buttonBPress={() => {
                console.log("B");
              }}
            />
          );
        })}
      </View>
    );
  };

  const ReservedSongSection = () => {
    return (
      <View style={styles.reservedSongSection}>
		<View style={{flex:1}}>
        <SectionBar
          title={"예약한 곡"}
          buttonName={"전체보기"}
          buttonOnPress={() => {
            console.log("예약한 곡 전체보기");
          }}
        />
		</View>
		<View style={{flex:4}}>
			<ReservedSongList />
		</View>
		<View style={{flex:1, marginTop:22}}>
        <BigButton
          text={"+ 곡 검색 및 예약하기"}
          onPress={() => {
            console.log("+ 곡 검색 및 예약하기");
          }}
        />
		</View>
      </View>
    );
  };

  const ReservedSongList = () => {
    return (
      <View style={styles.reservedSongListContainer}>
        {reservedSongSample.map((item) => {
          return (
            <ListItem
              styles={{ width: 375, height: 69 }}
              key={item.id}
              image={item.image}
              title={item.title}
              artist={"by " + item.artist}
              imageA={item.record ? icons.recordS : icons.play}
              imageB={icons.more}
              buttonAPress={() => {
                item.record ? console.log("A-Record") : console.log("A-Play");
              }}
              buttonBPress={() => {
                console.log("B");
              }}
            />
          );
        })}
      </View>
    );
  };
  //Render Items
  return (
    <Layout navigation={navigation} title="내 작업실" size={40} withButton image={userInfo.profile} onPress={()=>{console.log("profile clicked!")}}>
      <ScrollView
        style={{
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {/* <UserInfo /> */}
        <MySongSection/>
        <ReservedSongSection/>
      </ScrollView>
			<StatusBar style="dark" animated/>
    </Layout>
  );
}

const styles = ScaledSheet.create({
  container: {
    flexDirection: "column",
    justifyContent:'center',
  },
  reservedSongSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  reservedSongListContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 23,
  },

});
