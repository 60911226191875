import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useCallback } from "react";
import { StyleSheet, View, ScrollView, Platform } from "react-native";
import Layout from "../components/global/Layout";
import Text from "../components/utils/NotoFont";
import Footer from "../components/global/Footer";
import Item from "../components/global/Item";
import firebase from "firebase";
import "firebase/firestore";
import SectionBar from "../components/global/SectionBar";
import { ScaledSheet } from "react-native-size-matters";
import ListItem, {
  BigButton,
  Divider,
  SectionList,
} from "../components/global/ListItem";
import icons from "../constants/icons";
import Colors from "../constants/colors";
import {SearchBar} from "react-native-elements";
import { RefreshControl } from 'react-native-web-refresh-control'
import env from "../constants/env";
import { Menu, MenuItem, MenuDivider } from 'react-native-material-menu';

export default function ({ navigation }) {
  

  const [songs, setSongs] = useState([]);
  const [refreshing, setRefreshing] = useState(false)

  const reloadList = useCallback(() => {
    setRefreshing(true)

    getSongList().then(() => {
      setRefreshing(false)
    })
  }, [])

  useEffect(() => {
    (async () => {
      reloadList();
      // await getCommunityList();

  })();
},[reloadList] )

async function findSongList(text){
  fetch(`${env.apiUrl}/api/songs?title=${text}`, {
    method: "GET",
    mode: "cors",
    // headers: {
    //   Authorization: await getToken(),
    // }
  })
    .then(res => res.json())
    .then(json=> {
      setSongs(json);
    })
    .catch((error) => {
      console.error(error);
    });
};

async function getSongList(){
    fetch(`${env.apiUrl}/api/songs`, {
      method: "GET",
      mode: "cors",
      // headers: {
      //   Authorization: await getToken(),
      // }
    })
      .then(res => res.json())
      .then(json=> {
        setSongs(json);
      })
      .catch((error) => {
        Toast.show({
          type: "error",
          text1: error,
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        });
        console.error(error);
      });
  };

  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const reservedSongSample = [
    {
      id: 1,
      image: require("../assets/artist1.png"),
      title: "니가 남긴 영수증",
      artist: "블루블루",
      record: true,
    },
    {
      id: 2,
      image: require("../assets/artist2.png"),
      title: "오래전 그날, 우리",
      artist: "검정보라치마",
      record: true,
    },
    {
      id: 3,
      image: require("../assets/artist3.png"),
      title: "음치기 박치기",
      artist: "음치박치",
      record: false,
    },
    {
      id: 4,
      image: require("../assets/artist4.png"),
      title: "행복하지 말지 말아요",
      artist: "엠씨더미니멈",
      record: false,
    },
    {
      id: 5,
      image: require("../assets/artist5.png"),
      title: "니가 남긴 영수증",
      artist: "블루블루",
      record: true,
    },
    {
      id: 6,
      image: require("../assets/artist6.png"),
      title: "오래전 그날, 우리",
      artist: "검정보라치마",
      record: true,
    },
    {
      id: 7,
      image: require("../assets/artist7.png"),
      title: "음치기 박치기",
      artist: "음치박치",
      record: true,
    },
    {
      id: 8,
      image: require("../assets/artist8.png"),
      title: "행복하지 말지 말아요",
      artist: "엠씨더미니멈",
      record: false,
    },
  ];

  const ReservedSongSection = () => {
    return (
      <View style={styles.reservedSongSection}>
			<ReservedSongList />
      <BigButton
        isFull
          text={"음원으로 MR 만들기"}
          onPress={() => {
            navigation.navigate('AddNewSong');
          }}
          style={{marginBottom:10}}
        />
        <BigButton

          text={"랜덤으로 시작하기"}
          onPress={() => {
          let randomsongs = songs.filter(function(item){
              return item.processed == true;
           });
           
            let index = randomsongs[Math.floor(Math.random() * randomsongs.length)];
            navigation.navigate('Recording', {
              id: index._id,
            })    

          }}
        />
      </View>
    );
  };

  async function deleteSong(id){
    fetch(`${env.apiUrl}/api/songs/${id}`, {
      method: "DELETE",
      mode: "cors",
      // headers: {
      //   Authorization: await getToken(),
      // }
    })
      .then(res => reloadList())
      .catch((error) => {
        console.error(error);
      });
  };
  
  const ReservedSongList = () => {
    return (
      <ScrollView 
        style={{height:420, marginTop:10,}}
        containerStyle={styles.reservedSongListContainer}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={reloadList} />
        }>
        {songs.map((item) => {
          return (
            <ListItem
              onPress={()=>{
                navigation.navigate('Recording', {
                  id: item._id,
                })    
              }}
              disabled={!item.processed}
              styles={{ width: 375, height: 69 }}
              key={item._id}
              image={item._id ? {uri:`https://img.youtube.com/vi/${item._id}/0.jpg`} : require("../assets/artist1.png")}
              title={item.title}
              artist={"by " + item.artist}
              imageA={item.processed ? icons.recordS : icons.play}
              imageB={icons.more}
              buttonAPress={() => {
                item.record ? console.log("A-Record") : console.log("A-Play");
              }}
              buttonBPress={() => {
                // deleteSong(item._id);
              }}
            />
    
          );
        })}
      </ScrollView>
    );
  };

  const updateSearch = (text) => {
    setSearch(text);
    findSongList(text);
  }



  return (
    <Layout navigation={navigation} title="무슨 노래를 따라 불러볼까요?" size={26}>
      <View
        style={{
          flexDirection: "column",
          alignSelf: "center",
        }}
      >
        <SearchBar
          placeholder="노래를 검색하세요"
          onChangeText={(queryText) => updateSearch(queryText)}
          value={search}
          placeholderTextColor={Colors.gray}
          inputStyle={{ color: Colors.white, fontSize:14 }}
          containerStyle={{
            backgroundColor:'transparent',
            borderBottomColor: Colors.white,
            borderWidth:0,
            borderBottomWidth: 1,
            borderColor:'transparent',
            minWidth: 372,
            maxWidth:372,
          }}
          inputContainerStyle={{
            backgroundColor:'transparent',
            borderColor:'transparent',
            borderWidth:0,
          }}
        />
          <ReservedSongSection />
      </View>
    </Layout>
  );
}

const styles = ScaledSheet.create({
  reservedSongSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    ...Platform.select({
      ios: {
        height: 500,
      },
      android: {
        height:650,
      },
      default: {
        // other platforms, web for example
        height: 550
      }  
    })
  },
  reservedSongListContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  sectionListContainer: {
    marginTop: 16,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  sectionListItem: {
    width: 327,
    height: 52,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchBar: {
    minWidth: 327,
    alignSelf: "center",
    backgroundColor: "transparent",
    borderBottomColor: Colors.white,
    borderBottomWidth: 1,
  },
});
