import React, { useState } from "react";
import  AppLoading  from "expo-app-loading";
import { Asset } from "expo-asset";
import { StyleSheet, View } from "react-native";
import {
  SafeAreaView,
  SafeAreaProvider,
  initialWindowMetrics,
} from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import { useFonts } from "expo-font";
import "moment/locale/id";
import AppNavigator from "./navigation/AppNavigator";
import { AuthProvider } from "./provider/AuthProvider";
import icons from "./constants/icons";
import Toast from 'react-native-toast-message';
import Colors from "./constants/colors";
import Text from "./components/utils/NotoFont";



export default function App(props) {
  let [fontsLoaded] = useFonts({
    NotoR: require("./assets/fonts/NotoSansKR-Regular.otf"),
    NotoB: require("./assets/fonts/NotoSansKR-Bold.otf"),
  });
  const [isReady, setIsReady] = useState(false);

  const toastConfig = {
    success: (internalState) => (
      <View
        style={{ height: 60, width: 335, backgroundColor: Colors.primary, borderRadius:4,justifyContent: "center", alignItems: "center" }}
      >
        <Text style={{color:Colors.white, alignSelf:"center"}} bold>{internalState.text1}</Text>
      </View>
    ),
    error: (internalState) => (
      <View
        style={{ height: 60, width: 335, backgroundColor: Colors.warn, borderRadius:4 , justifyContent: "center", alignItems: "center"}}
      >
        <Text style={{color:Colors.white, alignSelf:"center",}} bold>{internalState.text1}</Text>
      </View>
    ),
    info: () => {},
    any_custom_type: () => {},
  };
  return fontsLoaded  ? (
	<SafeAreaProvider initialMetrics={initialWindowMetrics}>
	<SafeAreaView style={styles.container}>
	  <StatusBar style="auto" translucent />
	  <AuthProvider>
		<AppNavigator />
    <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
	  </AuthProvider>
	</SafeAreaView>
  </SafeAreaProvider>
  ) : (
	<AppLoading
	startAsync={loadResourcesAsync}
	onError={()=>handleLoadingError}
	onFinish={()=>setIsReady(true)}
  />


  );
}

async function loadResourcesAsync() {
  // load all resources such as images, fonts, etc.
  const cacheIcons = () => icons.map((icon)=> iconArray.push(icon));
  const iconArray = [];
  return await Promise.all([
    Asset.loadAsync(
	  [require("./assets/icon.png"), 
	  require("./assets/introImage.png"),
	  ],
    ),
  ]);
  // await SplashScreen.hideAsync();
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
