import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  StyleSheet,
  // TouchableOpacity,
  View,
  // ScrollView,
  // FlatList,
  Dimensions,
} from "react-native";
import * as firebase from "firebase";

import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
// import Footer from "../components/global/Footer";
// import MyCard from "../components/MyCard";

export default function ({ navigation }) {
const {
	width: SCREEN_WIDTH,
	height: SCREEN_HEIGHT,
	} = Dimensions.get('window');
	  
  const userInfo = {
    name: "홍길동",
  };

  const classDataSample = [
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 10,
      manager: "지창대",
      days: ["수요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실2",
      members: 4,
      manager: "강경묵",
      days: ["금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 7,
      manager: "류재춘",
      days: ["월요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 6,
      manager: "조영직",
      days: ["화요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 5,
      manager: "염동규",
      days: ["수요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 9,
      manager: "손지영",
      days: ["수요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 2,
      manager: "임대현",
      days: ["수요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 14,
      manager: "윤영철",
      days: ["수요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
    {
      title: "한빛복지관 - 두뇌튼튼 운동교실",
      members: 7,
      manager: "최윤석",
      days: ["수요일", "금요일"],
      time: "17:30~19:30",
      period: "2021.03.21~2021.05.21",
    },
  ];
  async function handleSignOut() {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <Layout navigation={navigation} title="SINGTUBE" size={50} withButton icon={"ios-menu"} onPress={handleSignOut}>
      <View style={styles.container}>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  cardContainer: {
    top: 100,
	width: "98%",
    left: "1%",
  },
  cardList: {
    margin: 15,
	width: "100%",
  },
  userInfo: {
    top: 50,
    left: 50,
    flex: 1,
    flexDirection: "column",
  },
  userInfoTitle: {
    fontSize: 50,
    fontWeight: "bold",
  },
  userInfoClass: {
    marginTop: 30,
    fontWeight: "bold",
    fontSize: 30,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    color: Colors.footerText,
    fontSize: 20,
    fontWeight: "bold",
  },
});
