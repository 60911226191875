import React,{useState} from "react";
import { StyleSheet, View, TouchableOpacity, Image,TextInput, Platform } from "react-native";
import Text from "../utils/NotoFont";
import Colors from "../../constants/colors";
import icons from "../../constants/icons";
import { Menu, MenuItem, MenuDivider } from 'react-native-material-menu';

const Button = (props) => {
  return (
    <TouchableOpacity style={styles.button} onPress={props.onPress}>
      <Image source={props.source} style={styles.buttonImg} />
    </TouchableOpacity>
  );
};
export const SearchBlock = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
        <View style={[styles.searchBlock]}>
      <Text numberOfLines={1} style={styles.searchBlockTxt} bold>
        {props.text}
      </Text>
      </View>
    </TouchableOpacity>
  );
};

export const BigButton = (props) => {
  return (
    <TouchableOpacity style={[styles.bigButton, props.style, {backgroundColor:props.isFull ? Colors.ActiveTab: Colors.transparent,  opacity: props.disabled? 0.5 : 1 }]}  onPress={props.onPress} disabled={props.disabled}>
      <Text style={[styles.bigButtonTxt, {color:props.isFull ? Colors.white:Colors.ActiveTab}]} bold>
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

export const Divider = () => {
  return <View style={styles.divider}></View>;
};

export const SectionList = (props) => {
  return (
    <TouchableOpacity style={{ ...props.style }} onPress={props.onPress}>
      <View
        style={{
          flex: 1,
          minWidth: 327,
          minHeight: 52,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flex: 9, flexDirection: "row", alignItems: "center" }}>
          <Image
            style={
              props.isActive
                ? styles.activeSectionImg
                : styles.inActiveSectionImg
            }
            source={props.imgA}
          />
          <Text
            style={
              props.isActive
                ? styles.activeSectionTxt
                : styles.inActiveSectionTxt
            }
          >
            {props.text}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Image
            style={
              props.isActive
                ? styles.activeSectionImg
                : styles.inActiveSectionImg
            }
            source={props.imgB}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const ArtistItem = (props) => {
  return (
    <View
      style={{
        ...props.style,
        flex: 1,
        minWidth: 300,
        minHeight: 60,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, flexDirection: "row" }}>
        <Image source={props.image} style={styles.artistProfile} />
      </View>
      <View style={[styles.titleContainer, { flex: 5, marginLeft: 12 }]}>
        <Text numberOfLines={1} style={styles.title} bold>
          {props.title}
        </Text>
        <Text numberOfLines={1} style={styles.artist}>
          {props.artist}
        </Text>
      </View>
    </View>
  );
};

const ListItem = (props) => {
  // const [visible, setVisible] = useState(false);
  // const hideMenu = () => setVisible(false);
  // const showMenu = () => setVisible(true);

  return( 
    // <Menu
    // visible={false}
    // anchor={
    //   // <Button onPress={showMenu} source={props.imageB} />
<TouchableOpacity
    disabled = {props.disabled}
    onPress={props.onPress}
    style={{
      ...props.style,
      width: 327,
      height: 69,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      opacity: props.disabled ? 0.5:1,
    }}
  >
    <View
      style={{ flex: 5, height:40, flexDirection: "row", justifyContent: "flex-start" }}
    >
      <Image source={props.image} style={styles.profile} />
      <View style={styles.titleContainer}>
        <Text numberOfLines={1} style={styles.title} bold>
          {props.title}
        </Text>
        <Text numberOfLines={1} style={styles.artist}>
          {props.artist}
        </Text>
      </View>
    </View>
    <View style={styles.buttonContainer}>
      <Button onPress={props.buttonAPress} source={props.imageA} />
    </View>
  </TouchableOpacity>
  //     }
  //     onRequestClose={hideMenu}
  // >

  // <MenuItem onPress={props.buttonBPress}>삭제</MenuItem>
  //     </Menu>

  )
};


export const SearchBar = (props) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TextInput
        autoCapitalize="none"
        numberOfLines={1}
        autoCorrect={false}
        clearButtonMode="always"
        value={props.value}
        onChangeText={props.onChangeText}
        placeholder={props.placeholder}
        placeholderTextColor={Colors.white}
        style={styles.searchBar}
      />
      <Image
        style={{
          padding: 40,
          tintColor: Colors.white,
          width: 22,
          height: 22,
        }}
        source={icons.search}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    minWidth: 220,
    fontWeight: "bold",
    color: Colors.white,
  },
  content: {
    textAlign: "right",
    marginRight: 15,
    fontSize: 20,
  },
  profile: {
    paddingLeft: 24,
    width: 45,
    height: 45,
    borderRadius: 10,
  },
  titleContainer: {
    flexDirection: "column",
    marginLeft: 16,
    height:69,
    ...Platform.select({
      ios: {
        top: -10,
      },
      android: {
        top:-10,
      },
      default: {
        // other platforms, web for example
        top: 0
      }
    })
  },
  title: {
    color: Colors.white,
    fontSize: 14,
  },
  artist: {
    fontSize: 12,
    color: Colors.gray,
    top:-20
  },
  button: {
    width: 21,
    height: 21,
    alignItems: "center",
    justifyContent: "center"
  },
  buttonImg: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
  },
  buttonContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignSelf: "center",
    alignItems: "center",
  },
  bigButton: {
    minWidth: 327,
    minHeight: 52,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: Colors.ActiveTab,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  bigButtonTxt: {
    alignSelf: "center",
    color: Colors.ActiveTab,
    fontSize: 16,
  },
  artistProfile: {
    width: 50,
    height: 50,
  },
  activeSectionImg: {
    width: 22,
    height: 22,
    tintColor: Colors.white,
  },
  inActiveSectionImg: {
    width: 22,
    height: 22,
    tintColor: Colors.gray,
  },
  activeSectionTxt: {
    marginLeft: 16,
    fontSize: 16,
    color: Colors.white,
  },
  inActiveSectionTxt: {
    marginLeft: 16,
    fontSize: 16,
    color: Colors.gray,
  },
  divider: {
    width: "100%",
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  searchBlock: {
    minWidth: 12,
    maxWidth:323,
    height: 27,
    borderWidth: 1,
    borderColor: Colors.gray,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 5,
    marginVertical:5,

  },
  searchBlockTxt: {
    fontSize: 12,
    paddingHorizontal: 10,
    alignSelf: "center",
    color: Colors.gray,
  },
  searchBar: {
    minWidth: 327,
    alignSelf: "center",
    backgroundColor: "transparent",
    borderBottomColor: Colors.white,
    borderBottomWidth: 1,
  },

});
export default ListItem;
