import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Colors from "../../constants/colors";
import { Ionicons } from "@expo/vector-icons";
import { scale, moderateScale, verticalScale } from "react-native-size-matters";
import Text from "../../components/utils/NotoFont";

export default function (props) {
  return (
    <View>
      <View
        style={{
          top:15,
          height: 60,
          flexDirection: "row",
          justifyContent:"flex-start"
        }}
      >
        {/* Back or Logo */}
        {props.withBack ? (
          <TouchableOpacity
            onPress={() => {
              props.navigation.goBack();
            }}
            style={{
              width:30, height:30,
              marginLeft: 24,
              color: Colors.white,
            }}
          >
            <Image source={require("../../assets/icon_back.png")} style={{width:30, height:30}}/>
          </TouchableOpacity>
        ) : (
          <View style={{ width:8 }}></View>
        )}
        {/* Title */}
        <View
          style={{
            justifyContent: "flex-start",
            color: Colors.white,
          }}
        >
          <Text
            style={{
              paddingLeft:16,
              fontSize: 20,
              color: Colors.white,
            }}
            bold
          >
            {props.title}
          </Text>
        </View>

        {/* Additional BUttons */}
        <View
          style={{
            alignItems: "flex-end",
            flex: 1,
          }}
        >
          {props.withButton ? (
            <TouchableOpacity
              onPress={props.onPress}
              style={{
                flex: 1,
                alignItems: "flex-start",
                color: Colors.white,
              }}
            >
              <Image source={props.source} style={{width:props.size,height:props.size}} />
            </TouchableOpacity>
          ) : (
            <View style={{ flex: 1, alignItems: "flex-end" }} />
          )}
        </View>
      </View>
    </View>
  );
}
