import React, { useState, useEffect, useContext } from "react";
import * as firebase from "firebase";
import firebaseApp from 'firebase/app'
import { Image, Platform, StyleSheet } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { scale, verticalScale } from "react-native-size-matters";

import { AuthContext } from "../provider/AuthProvider";
import TabBarText from "../components/utils/TabBarText";
import Colors from "../constants/colors";

// Main
import Home from "../screens/Home";
import Login from "../screens/Login";
import Star from "../screens/Favorite";
import Upload from "../screens/Upload";
import Search from "../screens/Search";
import Profile from "../screens/Profile";
import Recording from "../screens/Recording";
import Loading from "../screens/utils/Loading";
import icons from "../constants/icons";
import AddNewSong from "../screens/AddNewSong";

// const firebaseConfig = {
//   apiKey: "AIzaSyCvGlkgrL_387iGKYPiphZawAXn6f0k7RM",
//   authDomain: "noricare-235af.firebaseapp.com",
//   projectId: "noricare-235af",
//   storageBucket: "noricare-235af.appspot.com",
//   messagingSenderId: "1057513367194",
//   appId: "1:1057513367194:web:314ef7ea1fb6bb2a8ba123",
//   measurementId: "G-32RCPJJZSG",
// };
// if (firebaseApp.length === 0) {
//   firebase.initializeApp(firebaseConfig);
// }

const AuthStack = createStackNavigator();

const Auth = () => {
  return (
    <AuthStack.Navigator
      screenOptions={{
        headerMode: "none",
        headerShown: false,
      }}
    >
      <AuthStack.Screen name="Login" component={Login} />
    </AuthStack.Navigator>
  );
};
const MainStack = createStackNavigator();
const Main = () => {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerMode: "none",
        headerShown: false,
      }}
    >
      {/* <MainStack.Screen name="main" component={main} /> */}
      {/* <MainStack.Screen name="Star" component={Star} /> */}
      <MainStack.Screen name="Upload" component={Upload} />
      <MainStack.Screen name="AddNewSong" component={AddNewSong} />
      <MainStack.Screen name="Recording" component={Recording} />
      <MainStack.Screen name="Search" component={Search} />
      <MainStack.Screen name="Profile" component={Profile} />
    </MainStack.Navigator>
  );
};

// const Tabs = createBottomTabNavigator();

// const main = () => {
//   return (
//     <Tabs.Navigator
//       initialRouteName={"Upload"}
//       tabBarOptions={{
//         tabStyle: { borderTopWidth: 0, color: Colors.skyblue },
//         style: {
//           backgroundColor: Colors.background,
//           borderTopWidth: 0,
//           height: 60,
//           shadowColor: Colors.white,
//           shadowOpacity: 0.1,
//           shadowRadius: 20,
//           shadowOffset: {
//             height: -10,
//             width: 1,
//           },
//         },
//         activeTintColor: Colors.skyblue,
//       }}
//     >
//       {/* these icons using Ionicons */}
//       <Tabs.Screen
//         name="Home"
//         component={Home}
//         options={{
//           tabBarLabel: ({ focused }) => (
//             <TabBarText focused={focused} title="" />
//           ),
//           tabBarIcon: ({ focused }) => {
//             return (
//               <Image
//                 resizeMode={"contain"}
//                 source={focused ? icons.home_active : icons.home}
//                 style={[
//                   styles.tabIcon,
//                   focused ? styles.activeTintColor : styles.inActiveTintColor,
//                 ]}
//               />
//             );
//           },
//         }}
//       />
//       <Tabs.Screen
//         name="Search"
//         component={Search}
//         options={{
//           tabBarLabel: ({ focused }) => (
//             <TabBarText focused={focused} title="" />
//           ),
//           tabBarIcon: ({ focused }) => {
//             return (
//               <Image
//                 resizeMode={"contain"}
//                 source={focused ? icons.search_active : icons.search}
//                 style={[
//                   styles.tabIcon,
//                   focused ? styles.activeTintColor : styles.inActiveTintColor,
//                 ]}
//               />
//             );
//           },
//         }}
//       />

//       <Tabs.Screen
//         name="Upload"
//         component={Upload}
//         options={{
//           tabBarLabel: ({ focused }) => (
//             <TabBarText focused={focused} title="" />
//           ),
//           tabBarIcon: ({ focused }) => {
//             return (
//               <Image
//                 resizeMode={"contain"}
//                 source={focused ? icons.plus_active : icons.plus}
//                 style={[
//                   styles.tabIcon,
//                   focused ? styles.activeTintColor : styles.inActiveTintColor,
//                 ]}
//               />
//             );
//           },
//         }}
//       />

//       <Tabs.Screen
//         name="Star"
//         component={Star}
//         options={{
//           tabBarLabel: ({ focused }) => (
//             <TabBarText focused={focused} title="" />
//           ),
//           tabBarIcon: ({ focused }) => {
//             return (
//               <Image
//                 resizeMode={"contain"}
//                 source={focused ? icons.star_active : icons.star}
//                 style={[
//                   styles.tabIcon,
//                   focused ? styles.activeTintColor : styles.inActiveTintColor,
//                 ]}
//               />
//             );
//           },
//         }}
//       />
//       <Tabs.Screen
//         name="Profile"
//         component={Profile}
//         options={{
//           tabBarLabel: ({ focused }) => (
//             <TabBarText focused={focused} title="" />
//           ),
//           tabBarIcon: ({ focused }) => {
//             return (
//               <Image
//                 resizeMode={"contain"}
//                 source={focused ? icons.profile_active : icons.profile}
//                 style={[
//                   styles.tabIcon,
//                   focused ? styles.activeTintColor : styles.inActiveTintColor,
//                 ]}
//               />
//             );
//           },
//         }}
//       />
//     </Tabs.Navigator>
//   );
// };

const linking = {
  prefixes: ["https://singtube.kr", "singtube://"],
  config: {
    screens: {
      main: {
        path: "",
        screens: {
          Recording: "/recording",
          Profile: "/profile",
        },
      },
      Recording: {
        path: "/recording/:id",
        parse: {
          id: (id) => `${decodeURIComponent(id)}`,
        },
  
      },
    },
  },
};

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useContext(AuthContext);
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
  useEffect(() => {
    // onAuthStateChanged returns an unsubscriber
    // const unsubscribeAuth = firebase
    //   .auth()
    //   .onAuthStateChanged(async (authUser) => {
    //     try {
    //       await (authUser ? setUser(authUser) : setUser(null));
    //       setIsLoading(false);
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   });

    // // unsubscribe auth listener on unmount
    // return unsubscribeAuth;
  }, []);

  return (
    <NavigationContainer linking={linking}>
      {/* {isLoading == true && <Loading />} */}
      {/* {isLoading == false && (user ? <Main /> : <Auth />)} */}
      {<Main />}
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  tabIcon: {
    width: 28,
    height: 28,
    ...Platform.select({
      ios: {
        top: 24
      },
      android: {
      },
      default: {
        // other platforms, web for example
      }
    }),
  },
  activeTintColor: {
    tintColor: Colors.ActiveTab,
  },
  inActiveTintColor: {
    tintColor: Colors.inActiveTab,
  },
});
