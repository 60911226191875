import React, { useState } from "react";
import {  View, ScrollView, TextInput, Platform } from "react-native";
import Layout from "../components/global/Layout";
import Text from "../components/utils/NotoFont";
import "firebase/firestore";
import icons from "../constants/icons";
import Colors from "../constants/colors";
import env from "../constants/env";
import {BigButton} from "../components/global/ListItem";
import { getLyrics } from "genius-lyrics-api";
import Clipboard from "@react-native-clipboard/clipboard";
import * as Notifications from 'expo-notifications';
import Toast from "react-native-toast-message";
import { ScaledSheet } from "react-native-size-matters";

export default function ({ navigation }) {
  const [isLoading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [linkText, setLinkText] = useState("");
  const [artist, setArtist] = useState("");
  const [title, setTitle] = useState("");
  const [lyrics, setLyrics] = useState("");

  const fetchCopiedText = async () => {
    const text = await Clipboard.getString();
    setLinkText(text);
    console.log(youTubeIdFromLink(text));
  };
  const youTubeIdFromLink = (url) => url.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/)[1];

  const removeEmptyLine =(text) => {
    return text.replace(/(^[ \t]*\n)/gm, "");
  }
  
  const options = {
    apiKey: "FOy263eo4ANrxeeM4NhyyLtVSk5br0Hm7G11xuz2QWt2mqfWcKWzIphDMwrE5M2s",
    title: title,
    artist: artist,
    optimizeQuery: true,
  };

  const url = `${env.apiUrl}`;

  async function getLyricsFromServer() {
    return new Promise(function (resolve, reject) {
      // 1. initialize request
      const xhr = new XMLHttpRequest();
      // 2. open request
      xhr.open("POST", url+'/lyrics');
      // 3. set up callback for request
      xhr.onload = () => {
        const response = JSON.parse(xhr.response);

        console.log(response);

        return resolve(response);
        
        // ... do something with the successful response
      };
      // 4. catch for request error
      xhr.onerror = (e) => {
        console.log(e, "upload failed");
      };
      // 4. catch for request timeout
      xhr.ontimeout = (e) => {
        console.log(e, "upload timeout");
      };
      // REPLACE #4 WITH THE CODE BELOW IF YOU'RE UPLOADING AN ARRAY OF MULTIPLE FILES
      // 4. create formData to upload
      const formData = new FormData();
      // formData.append("file", audioFile);
      formData.append("artist", artist);
      formData.append("title", title);
      console.log(artist, title);

      // 6. upload the request
      xhr.send(formData);
      // 7. track upload progress
    });
  }

  async function requestMR() {
    return new Promise(function (resolve, reject) {
      // 1. initialize request
      const xhr = new XMLHttpRequest();
      // 2. open request
      xhr.open("POST", url+'/api/songs');
      // 3. set up callback for request
      xhr.onload = () => {
        console.log(xhr.status);
        const response = JSON.parse(xhr.response);

        console.log(response);
        let bodyText = `노래${artist}-${title}를 요청하였습니다!`;
        Toast.show({
          type: "success",
          text1: bodyText,
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        });
        xhr.status == 200 ? (uploadSong(),setIsProcessing(true)) : Toast.show({
          type: "error",
          text1: '이미 존재하는 노래입니다!',
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        });

        return resolve(response);
        
        // ... do something with the successful response
      };
      // 4. catch for request error
      xhr.onerror = (e) => {
        console.log(e, "upload failed");
        Toast.show({
          type: "error",
          text1: '이미 존재하는 노래입니다!',
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        });
      };
      // 4. catch for request timeout
      xhr.ontimeout = (e) => {
        console.log(e, "upload timeout");
      };
      // REPLACE #4 WITH THE CODE BELOW IF YOU'RE UPLOADING AN ARRAY OF MULTIPLE FILES
      // 4. create formData to upload
      const formData = new FormData();
      // formData.append("file", audioFile);
      formData.append("youtube", youTubeIdFromLink(linkText));
      formData.append("lyrics", removeEmptyLine(lyrics));
      formData.append("title", title);
      formData.append("artist", artist);
      // 6. upload the request
      xhr.send(formData);
      // 7. track upload progress
    });
  }
  async function uploadSong() {
    return new Promise(function (resolve, reject) {
      // 1. initialize request
      const xhr = new XMLHttpRequest();
      // 2. open request
      xhr.open("POST", url+'/align');
      // 3. set up callback for request
      xhr.onload = () => {
        const response = JSON.parse(xhr.response);

        console.log(response);
        let bodyText = `노래${artist}-${title}이(가)준비되었습니다`;
        Platform.OS !== 'web' ?
        Notifications.scheduleNotificationAsync({
          content: {
            title: "작업이 완료되었습니다!",
            body: bodyText,
          },
          trigger: {
            seconds: 0, //onPress가 클릭이 되면 60초 뒤에 알람이 발생합니다.
          },
        }) : console.log("");    
        Toast.show({
          type: "success",
          text1: "작업이 완료되었습니다!",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        });
        return resolve(response);
        
        // ... do something with the successful response
      };
      // 4. catch for request error
      xhr.onerror = (e) => {
        console.log(e, "upload failed");
      };
      // 4. catch for request timeout
      xhr.ontimeout = (e) => {
        console.log(e, "upload timeout");
      };
      // REPLACE #4 WITH THE CODE BELOW IF YOU'RE UPLOADING AN ARRAY OF MULTIPLE FILES
      // 4. create formData to upload
      const formData = new FormData();
      // formData.append("file", audioFile);
      formData.append("youtube", youTubeIdFromLink(linkText));
      formData.append("lyrics", removeEmptyLine(lyrics));
      formData.append("title", title);
      formData.append("artist", artist);
      // 6. upload the request
      xhr.send(formData);
      // 7. track upload progress
      if (xhr.upload) {
        // track the upload progress
        xhr.upload.onprogress = ({ total, loaded }) => {
          const uploadProgress = loaded / total;
          console.log(uploadProgress);
        };
      }
    });
  }

  return (
    <Layout withBack navigation={navigation} title="새로운 노래 추가" size={26}>
      <ScrollView
        style={{
          flexDirection: "column",
        }}
      >
        {isProcessing ? (
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <Text style={{ color: Colors.white, alignSelf: "center" }}>
              {
                "MR을 만들고 있어요. \n약 2~3분 소요됩니다.\n작업이 완료되면 알려드릴게요!"
              }
            </Text>
          </View>
        ) : (
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <Text
              style={{ fontSize: 20, color: Colors.white, marginBottom: 10 }}
              bold
            >
              {"Youtube 링크로 가져오기"}
            </Text>
            {!linkText && (
              <BigButton
                text={"복사한 URL 링크 붙여넣기"}
                onPress={() => {
                  fetchCopiedText();
                }}
              />
            )}
            <TextInput
              value={linkText}
              placeholder={"URL 링크 직접 입력"}
              style={styles.textInput}
              onChangeText={(text) => setLinkText(text)}
              placeholderTextColor={Colors.lightGray}
            />
            <TextInput
              value={artist}
              placeholder={"아티스트 명"}
              style={styles.textInput}
              onChangeText={(text) => setArtist(text)}
              placeholderTextColor={Colors.lightGray}
            />
            <TextInput
              value={title}
              placeholder={"곡 제목"}
              style={styles.textInput}
              onChangeText={(text) => setTitle(text)}
              placeholderTextColor={Colors.lightGray}
            />
            {/* <Text style={{ fontSize: 14, color: Colors.white }} bold>
              {"파일 업로드"}
            </Text>
            <BigButton text={"찾아보기"} onPress={() => {}} /> */}
          </View>
        )}
        {isProcessing ? (
          <BigButton
            isFull
            text={"다른 작업 하면서 기다리기"}
            onPress={() => {
              navigation.goBack();
            }}
          />
        ) : (
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <BigButton
              isFull
              text={"가사 입력/가져오기"}
              onPress={() => {
                Platform.OS === 'web' ? getLyricsFromServer().then((lyrics) => setLyrics(removeEmptyLine(lyrics))) : getLyrics(options).then((lyrics) => setLyrics(removeEmptyLine(lyrics)));
              //   getSong(options).then((song) =>
              //     console.log(`
              // ${song.id}
              // ${song.url}
              // ${song.albumArt}
              // ${song.lyrics}`)
              //   );
              }}
              disabled={ artist == "" || title == "" ? true : false}
            />
            <TextInput
              value={lyrics}
              multiline={true}
              placeholder={"가사 입력... 한줄씩 입력해 주세요"}
              style={[styles.textInput, { height: 300 }]}
              onChangeText={(text) => setLyrics(text)}
              placeholderTextColor={Colors.lightGray}
            />
            <BigButton
              isFull
              disabled={ (lyrics == "" || title == "" || linkText == "" )? true : false}
              text={"Vocal / MR(Inst.) 분리하기"}
              onPress={() => {
                requestMR();
                
              }}
            />
          </View>
        )}
      </ScrollView>
    </Layout>
  );
}

const styles = ScaledSheet.create({
  textInput: {
    fontSize: 20,
    color: Colors.white,
    width: 327,
    height: 27,
    borderBottomColor: Colors.white, // Add this to specify bottom border color
    borderBottomWidth: 2, // Add this to specify bottom border thickness
    marginVertical: 10,
  },
  sectionListContainer: {
    flex: 1,
    marginTop: 16,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  sectionListItem: {
    minWidth: 327,
    minHeight: 52,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
