import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import Layout from "../components/global/Layout";
import Text from "../components/utils/NotoFont";
import Footer from "../components/global/Footer";
import Item from "../components/global/Item";
import firebase from "firebase";
import "firebase/firestore";
import SectionBar from "../components/global/SectionBar";
import { ScaledSheet } from "react-native-size-matters";
import ListItem, {
  BigButton,
  Divider,
  SectionList,
} from "../components/global/ListItem";
import icons from "../constants/icons";

export default function ({ navigation }) {
  const [isLoading, setLoading] = useState(true);

  const reservedSongSample = [
    {
      id: 1,
      image: require("../assets/artist1.png"),
      title: "니가 남긴 영수증",
      artist: "블루블루",
      record: true,
    },
    {
      id: 2,
      image: require("../assets/artist2.png"),
      title: "오래전 그날, 우리",
      artist: "검정보라치마",
      record: true,
    },
    {
      id: 3,
      image: require("../assets/artist3.png"),
      title: "음치기 박치기",
      artist: "음치박치",
      record: false,
    },
    {
      id: 4,
      image: require("../assets/artist4.png"),
      title: "행복하지 말지 말아요",
      artist: "엠씨더미니멈",
      record: false,
    },
  ];

  const ReservedSongSection = () => {
    return (
      <View style={styles.reservedSongSection}>
		<View style={{flex:1}}>
        <SectionBar
          title={"내가 부른"}
          buttonName={"전체보기"}
          buttonOnPress={() => {
            console.log("최근에 부른 전체보기");
          }}
        />
		</View>
		<View style={{flex:4}}>
			<ReservedSongList />
		</View>
		<View style={{flex:1, marginTop:22}}>
        <BigButton
          text={"+ 곡 검색 및 예약하기"}
          onPress={() => {
            console.log("+ 곡 검색 및 예약하기");
          }}
        />
		</View>
      </View>
    );
  };

  const ReservedSongList = () => {
    return (
      <View style={styles.reservedSongListContainer}>
        {reservedSongSample.map((item) => {
          return (
            <ListItem
              styles={{ width: 375, height: 69 }}
              key={item.id}
              image={item.image}
              title={item.title}
              artist={"by " + item.artist}
              imageA={item.record ? icons.recordS : icons.play}
              imageB={icons.more}
              buttonAPress={() => {
                item.record ? console.log("A-Record") : console.log("A-Play");
              }}
              buttonBPress={() => {
                console.log("B");
              }}
            />
          );
        })}
      </View>
    );
  };
  const sectionList = [
    {
      text: "노래방에 예약",
      imgA: icons.reservedList,
      imgB: icons.chevron,
      get goto () {
        return console.log(this.text);
	  },
	  isActive:false,
    },
    {
      text: "내가 부른",
      imgA: icons.mySong,
      imgB: icons.chevron,
      get goto () {
        return console.log(this.text);
      },
	  isActive:true,
    },
    {
      text: "나만의 아티스트",
      imgA: icons.myArtist,
      imgB: icons.chevron,
      get goto () {
        return console.log(this.text);
      },
    },
    {
      text: "친구가 부른",
      imgA: icons.myFriends,
      imgB: icons.chevron,
      get goto () {
        return console.log(this.text);
      },
    },
  ];
  const SectionListContainer = () => {
    return (
      <View style={styles.sectionListContainer}>
		  {sectionList.map((item,index)=>
		  <View key={index}
		  >
        <Divider/>
		<SectionList 
		  style={styles.sectionListItem}
          onPress={item.goto}
          text={item.text}
          imgA={item.imgA}
		  imgB={item.imgB}
		  isActive={item.isActive}
		  />
		  </View>
		  )}
      </View>
    );
  };

  return (
    <Layout navigation={navigation} title="나의 18번" size={26}>
      <StatusBar style="dark" />
      <ScrollView
        style={{
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
          <ReservedSongSection />
          <SectionListContainer />
      </ScrollView>
    </Layout>
  );
}

const styles = ScaledSheet.create({
  reservedSongSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  reservedSongListContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 23,
  },
  sectionListContainer: {
    flex: 1,
    marginTop: 16,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  sectionListItem: {
    minWidth: 327,
    minHeight: 52,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
