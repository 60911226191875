import React, { useState, useEffect, useRef, useMemo } from "react";
import { View, StyleSheet, Animated, TouchableOpacity, Platform } from "react-native";
import Colors from "../constants/colors";
import { moderateScale, verticalScale } from "react-native-size-matters";
import Text from "./utils/NotoFont";

const defaultWrapperStyle = {
  position: "relative",
  display: "inline-block",
  flexDirection: "row",
};

const defaultFontStyle = {
  fontSize: 36,
  color: Colors.white,
  flexWrap: "wrap",
};

const defaultActiveStyle = {
  fontSize: 36,
  flexWrap: "wrap",
  ...Platform.select({
    ios:{
      top:0,
    },
    android:{
      top:0,
    },
    default:{
      top:-36
    }
  }),
  width:1000,
  color: Colors.primary,
  zIndex: 1,
};


const KaraokeWord = (props) => {
  const { duration, text, currentPosition, start,end} = props;

  const computePercent = useMemo(()=> {
    if(currentPosition<=start)
      return 0;
    if(currentPosition>=end)
      return 100;
    else{
      let startPercent = currentPosition-start;
      return startPercent/duration*100
    }
  },[currentPosition])

  let { wrapperStyle, fontStyle, activeStyle } = props;

  wrapperStyle = wrapperStyle
    ? {
        ...defaultWrapperStyle,
        ...wrapperStyle,
      }
    : defaultWrapperStyle;
  fontStyle = fontStyle
    ? {
        ...defaultFontStyle,
        ...fontStyle,
      }
    : defaultFontStyle;
  activeStyle = activeStyle
    ? {
        ...defaultActiveStyle,
        ...activeStyle,
        // width: `${dynamicPercentage}%`,
      }
    : {
        ...defaultActiveStyle,
        // width: `${dynamicPercentage}%`,
      };
  return (
    <TouchableOpacity onPress={props.onPress} style={[props.style, styles.textWrapper]}>
      <View style={[props.style, styles.textWrapper]}>
      <View style={{ alignItems: 'baseline', overflow: "hidden"}}>
            <Text style={[fontStyle, {color:props.isFound ? Colors.primary : Colors.white }]} bold >{text}</Text>
      </View>
        <View // Special animatable View
          style={{
            position: "absolute",
            alignItems: 'baseline',
            marginLeft:0,
            overflow: "hidden",
            width: computePercent+"%", // Bind width to animated value
          }}
        >
          {Platform.OS === 'web' ?
          (<Text style={activeStyle} bold>
            {text}
          </Text>) : (<Text style={activeStyle} numberOfLines={1} bold>
            {text}
          </Text>)
          }
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  textWrapper: {
    justifyContent: "center",
    maxHeight:50,
  },
});

export default React.memo(KaraokeWord);
