const home = require('../assets/icon_home_in.png');
const home_active = require('../assets/icon_home_in.png');
const plus = require('../assets/icon_upload_in.png');
const plus_active = require('../assets/icon_upload_in.png');
const song = require('../assets/icon_song.png');
const song_active = require('../assets/icon_song.png');
const profile = require('../assets/icon_profile_in.png');
const profile_active = require('../assets/icon_profile_a.png');
const star = require('../assets/icon_star_in.png');
const star_active = require('../assets/icon_star_a.png');
const record = require('../assets/icon_record.png');
const pause = require('../assets/icon_pause.png');
const tempo = require('../assets/icon_tempo.png');
const favorite = require('../assets/icon_favorite.png');
const random = require('../assets/icon_random.png');
const pitch = require('../assets/icon_pitch.png');
const prev = require('../assets/icon_prev.png');
const backward = require('../assets/icon_backward.png');
const back = require('../assets/icon_back.png');
const viewCount = require('../assets/icon_viewCount.png');
const newLabel = require('../assets/newLabel.png');
const recordBg = require('../assets/icon_recordBg.png');
const recordS = require('../assets/icon_recordS.png');
const more = require('../assets/icon_more.png');
const play = require('../assets/icon_play.png');
const chevron = require('../assets/icon_chevron.png');
const mySong = require('../assets/icon_mySong.png');
const myArtist = require('../assets/icon_myArtist.png');
const myFriends = require('../assets/icon_myFriends.png');
const reservedList = require('../assets/icon_reservedList.png');
const search = require('../assets/icon_search_in.png');
const search_active = require('../assets/icon_search_a.png');
const comment = require('../assets/icon_comment.png');
const commentA = require('../assets/icon_comment_a.png');
const heart = require('../assets/icon_heart.png');
const heartA = require('../assets/icon_heart_a.png');
const share = require('../assets/icon_share.png');
const user = require('../assets/icon_user.png');
const save = require('../assets/icon_save.png');
const whole = require('../assets/icon_whole.png');
const wholeA = require('../assets/icon_whole_a.png');
const repeat = require('../assets/icon_repeat.png');
const repeatA = require('../assets/icon_repeat_a.png');
const lyrics = require('../assets/icon_lyrics.png');
const lyricsA = require('../assets/icon_lyrics_a.png');
const next = require('../assets/icon_next.png');
const pauseBtn = require('../assets/icon_pauseBtn.png');
const playBtn = require('../assets/icon_playBtn.png');
const recordBtn = require('../assets/icon_recordBtn.png');
const changeRecordBtn = require('../assets/icon_changeRecord.png');
const changeListenBtn = require('../assets/icon_changeListen.png');
const searchNext = require('../assets/icon_searchNext.png');

export default {
	home,
	home_active,
	song,
	song_active,
	plus,
	plus_active,
	profile,
	profile_active,
	star,
	star_active,
	record,
	pause,
	tempo,
	favorite,
	random,
	pitch,
	prev,
	backward,
	back,
	viewCount,
	newLabel,
	recordBg,
	recordS,
	more,
	play,
	chevron,
	mySong,
	myArtist,
	myFriends,
	reservedList,
	search,
	search_active,
	comment,
	commentA,
	heart,
	heartA,
	share,
	user,
	save,
	whole,
	wholeA,
	repeat,
	repeatA,
	lyrics,
	lyricsA,
	next,
	pauseBtn,
	recordBtn,
	playBtn,
	changeListenBtn,
	changeRecordBtn,
	searchNext
};
