import React from 'react';
import { Text } from 'react-native';
export default function (props) {
	return (
		<Text
			{...props}
			style={[
				props.style,
				{
					fontFamily: props.bold
						? 'NotoB'
						: props.medium
						? 'NotoR'
						: 'NotoR',
				},
			]}
		/>
	);
}
